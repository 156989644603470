import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
// import { getProviders, signIn } from 'next-auth/react'
import { signOut } from 'next-auth/react'
import styled from 'styled-components'
import AuthLayout from 'components/login/auth-layout'
import LoginForm from 'components/login/login-form'
import ActivateAccountForm from 'components/login/activate-account-form'
import { TransparentButton } from 'components/common/button'
import ForgetPassword from 'components/login/forget-password'
import { beat } from 'components/animations'
import AuthRedirect from 'components/login/auth-redirect'
import pushToDataLayer from 'utils/push-to-datalayer'
import GithubError from 'components/login/github-error'
import LoginMessages from 'components/login/login-messages'

const ForgetButton = styled(TransparentButton)`
  display: block;
  font: 600 14px/24px var(--primaryFont);
  margin: 16px auto 0;
  color: var(--secondary);
  text-decoration: underline;
  letter-spacing: 0.0125em;
  &:hover {
    color: var(--secondaryHover);
  }
  &:active {
    color: var(--secondaryActive);
  }
  @media(min-width: 768px) {
    font: 600 16px/24px var(--primaryFont);
  }
`

const OperationSuccess = styled.div`
  margin: 1rem auto;
  color: var(--success);
  font-weight: 600;
  width: fit-content;
  margin: 1rem auto;
  ${beat()}
`

function Login() {
  const [changePassword, setChangePassword] = useState(false)
  const router = useRouter()
  const {
    query: {
      payment, register, activate_account, github_error, is_free,
    },
  } = router
  useEffect(() => {
    if (payment) {
      pushToDataLayer({
        event: 'mailConfirmation',
        eventCategory: 'account',
        eventAction: 'giveAccess',
        dl_valueConfirmation: true,
        ecommerce: {
          checkout: {
            actionField: { step: 3 },
          },
        },
      })
    }
  }, [])
  return (
    <AuthLayout
      title={is_free ? 'El curso es gratis solo debes crear una cuenta para guardar tu avance' : 'Inicia sesión y comienza tu carrera profesional'}
      authDisabled={changePassword || activate_account}
      googleCta="Continuar con Google"
      githubCta="Continuar con GitHub"
      query="¿Aún no tienes cuenta?"
      link={is_free ? '/registro?is_free=true' : '/registro'}
      linkCta="Regístrate"
    >
      {activate_account ? (
        <ActivateAccountForm />
      ) : (
        <>
          {changePassword ? (
            <>
              <ForgetPassword>
                <ForgetButton onClick={() => setChangePassword(false)}>
                  Volver a inicio de sesión
                </ForgetButton>
              </ForgetPassword>
            </>
          ) : (
            <>
              {register && (
                <OperationSuccess data-cy="register-success">
                  <p>¡Ya estas registrado!</p>
                  <p>Inicia sesión con correo y contraseña.</p>
                </OperationSuccess>
              )}
              <LoginForm />
              <ForgetButton onClick={() => setChangePassword(true)} data-cy="forget-password">
                {'¿Olvidaste tu contraseña?'}
              </ForgetButton>
              <GithubError error={github_error} />
            </>
          )}
        </>
      )}
      <LoginMessages />
    </AuthLayout>
  )
}

export const getServerSideProps = AuthRedirect

Login.pageTheme = {
  background: 'var(--sky)',
}

export default Login
