import styled from 'styled-components'
import { SecondaryButton } from 'common/button'
import { Typography } from 'common/typography'
import InputField from 'common/input-field'
import useInputValue from 'hooks/use-input-value'
import Message from 'common/message'
import { CircularProgress } from '@material-ui/core'
import { useForgetPassword } from 'mutations/session'

const ForgetPasswordStyled = styled.div`
  position: relative;
  grid-template: repeat(3, min(1fr, 48px) / 1fr);
  display: grid;
  row-gap: 16px;
  form {
    display: grid;
    row-gap: 16px;
  }
  h1 {
    text-align: left;
  }
  .recovered-email {
    font-weight: 600;
  }
  .back-btn button {
    margin: 0 auto;
  }
  .back-btn-success button {
    margin: 0;
  }
`

const Loader = styled.div`
  position: absolute;
  top: -4rem;
  right: 1rem;
  .MuiCircularProgress-circle {
    stroke: var(--primary);
  }
  @media screen and (min-width: 1024px) {
    right: 2rem;
  }
`

function ForgetPassword({ children }) {
  const { mutate, isLoading, isSuccess, error } = useForgetPassword()
  const recoverEmail = useInputValue('')
  const recoverPassword = async (event) => {
    event.preventDefault()
    if (recoverEmail.disable) return
    mutate(recoverEmail.value)
  }
  return (
    <ForgetPasswordStyled>
      {isLoading && (
        <Loader>
          <CircularProgress variant="indeterminate" size={25} thickness={5} />
        </Loader>
      )}
      {isSuccess ? (
        <>
          <Typography as="h1" small="regularBold" large="headline5">
            ¡Todo listo!
          </Typography>
          <Typography font="p2Regular" data-cy="recover-success">
            Te enviamos un enlace al correo
            {' '}
            <span className="recovered-email">{recoverEmail.value}</span>
            . Sigue las instrucciones para restablecer tu contraseña.
          </Typography>
        </>
      ) : (
        <form onSubmit={recoverPassword}>
          <Typography as="h1" small="regularBold" large="headline5">
            Restablecer contraseña
          </Typography>
          <InputField
            label="Correo electrónico"
            onChange={recoverEmail.onChange}
            value={recoverEmail.value}
            name="recover-email"
            type="email"
            placeholder="Ingresa tu correo"
            data-cy="recover-input"
            required
            fullWidth
          />
          {error && <Message margin="0" align="center" error data-cy="recover-error">El correo que ingresaste es invalido :(</Message>}
          {!isSuccess && (
            <SecondaryButton
              fontWeight={500}
              fullWidth
              padding={16}
              height="48"
              data-cy="recover-btn"
              disabled={recoverEmail.disable}
            >
              {'Restablecer mi contraseña'}
            </SecondaryButton>
          )}
        </form>
      )
      }
      <div className={isSuccess ? 'back-btn-success' : 'back-btn'}>
        {children}
      </div>
    </ForgetPasswordStyled>
  )
}

export default ForgetPassword
