import styled from 'styled-components'
import useInputValue from 'hooks/use-input-value'
import Message from 'common/message'
import { SecondaryButton } from 'common/button'
import { useRouter } from 'next/router'
import InputField from 'common/input-field'
import { useActivateAccount } from 'mutations/session'
import { Typography } from 'common/typography'
import Notification from 'common/notification'

const ActivateAccountFormStyled = styled.form`
  display: grid;
  row-gap: 16px;
`

function ActivateAccountForm() {
  const password = useInputValue('')
  const confirmPassword = useInputValue('')
  const { query: { email } } = useRouter()
  const { mutate, isError, isLoading } = useActivateAccount()
  const equalValues = password.value === confirmPassword.value
  const disabled = password.disable || confirmPassword.disable || isLoading || !equalValues
  const activateAccount = (e) => {
    if (!disabled) {
      const payload = new FormData(e.target)
      mutate(payload)
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    activateAccount(e)
  }
  const hadleKeyPress = (e) => {
    if (e.which === 13) {
      e.preventDefault()
      activateAccount(e)
    }
  }
  return (
    <>
      <Typography as="h1" small="regularBold" large="headline5">
        Activar cuenta
      </Typography>
      <ActivateAccountFormStyled method="POST" onSubmit={handleSubmit}>
        <Typography>
          Define la contraseña que te gustaría utilizar en tu cuenta
          {' '}
          <b>{email}</b>
        </Typography>
        <InputField
          label="Contraseña"
          onChange={password.onChange}
          value={password.value}
          name="password"
          type="password"
          data-cy="activate-password"
          placeholder="Ingresa tu contraseña"
          required
          fullWidth
        />
        <InputField
          label="Confirmar contraseña"
          onChange={confirmPassword.onChange}
          value={confirmPassword.value}
          onKeyPress={hadleKeyPress}
          name="confirm_password"
          type="password"
          data-cy="activate-confirm-password"
          placeholder="Confirma tu contraseña"
          required
          fullWidth
        />
        {!equalValues && (
          <Message margin="0" error>Las contraseñas no coinciden</Message>
        )}
        {isError && (
          <Notification
            title="Tu cuenta no ha sido activada"
            message="El codigo de activacion es incorrecto o a expirado."
            error
          />
        )}
        <SecondaryButton
          type="submit"
          id="activate-submit"
          fontWeight={500}
          padding={16}
          height="48"
          fullWidth
          data-cy="login-submit"
          disabled={disabled}
        >
          {'Iniciar sesión'}
        </SecondaryButton>
      </ActivateAccountFormStyled>
    </>
  )
}

export default ActivateAccountForm
