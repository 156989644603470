import styled from 'styled-components'
import { P } from 'common/typography'
import { MdCheck } from 'react-icons/md'
import { AiOutlineExclamationCircle } from 'react-icons/ai'

const NotificationStyled = styled.div`
  max-width: 300px;
  background: #E5E5E5;
  padding: 16px;
  position: fixed;
  bottom: 24px;
  right: 24px;
  ${({ position }) => (position === 'top' && `
    top: 80px;
    bottom: initial;
    right: 24px;
  `)}
  border-radius: 4px;
  border: 1px solid ${({ error }) => (error ? '#E74C3C' : '#34A853')};
  border-left-width: 8px;
  animation: vanish ease-in 1s 12s forwards;
  display: flex;
  z-index: 2;
  .notification {
    margin-left: 16px;
  }
  @keyframes vanish {
    from {
      opacity: 1;
      transform: scale3D(1, 1, 1);
    }
    to {
      opacity: 0;
      transform: scale3D(0.95, 0.95, 0.95);
    }
  }
  @media(min-width: 768px) {
    bottom: 64px;
    ${({ position }) => (position === 'top' && `
      bottom: initial;
    `)}
    right: 82px;
    max-width: 350px;
  }
`

function Notification({
  title, message, error, dataCy, position, report
}) {
  return (
    <NotificationStyled error={error} data-cy={dataCy} position={position}>
      {error ? (
        <AiOutlineExclamationCircle size="24px" color="#E74C3C" />
      ) : (
        <MdCheck size="24px" color="#34A853" />
      )}
      <div className="notification">
        <P color="var(--neutral100)" fontSize="14" fontWeight="700" lineHeight="24">{title}</P>
        <P color="var(--neutral90)" fontSize="12" lineHeight="24">{message}</P>
        {report ? (
          <P color="var(--neutral90)" fontSize="12" lineHeight="24">
            Por favor reportalo a
            {' '}
            <a href="mailto:me@leonidasesteban.com">me@leonidasesteban.com</a>
          </P>
        ) : null}
      </div>
    </NotificationStyled>
  )
}

export default Notification
