import { useState, useEffect } from 'react'
import styled from 'styled-components'
import useInputValue from 'hooks/use-input-value'
import Message from 'common/message'
import { SecondaryButton } from 'common/button'
import InputField from 'common/input-field'
import { useLoginWithCredentials } from 'mutations/session'
import { useQueryClient } from 'react-query'

const AuthFormStyled = styled.form`
  text-align: start;
  display: grid;
  grid-template: repeat(3, min(1fr, 48px) / 1fr);
  row-gap: 16px;
  .form_group {
    padding: 14px 12px 14px 12px;
    position: relative;
    color: var(--baseSecondary);
    border: 1px solid var(--textField);
    margin: 32px 0;
    border-radius: 8px;
    &:last-of-type{
      margin-bottom: 24px;
    }
  }
  input:active > .form_group {
    border-color: #0791e6;
  }
`

function AuthForm() {
  const queryClient = useQueryClient()
  const session = queryClient.getQueryData('SESSION')
  const [error, setError] = useState(session?.error || false)
  const { mutate, isLoading: loading } = useLoginWithCredentials()

  useEffect(() => {
    if (session?.error) {
      setError(true)
    }
  }, [session])

  const handleInputChange = () => {
    if (error) {
      setError(false)
    }
  }

  const user = useInputValue('', handleInputChange)
  const password = useInputValue('', handleInputChange)
  const disabled = user.disable || password.disable

  function login() {
    if (user.value && password.value && !loading) {
      const data = {
        user: user.value.toLowerCase(),
        password: password.value,
      }
      mutate(data)
      queryClient.setQueryData('SESSION', (sesion) => ({ ...sesion, error: false }))
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    login()
  }
  const hadleKeyPress = (event) => {
    if (event.which === 13) {
      event.preventDefault()
      login()
    }
  }
  return (
    <AuthFormStyled method="POST" onSubmit={handleSubmit}>
      <InputField
        label="Correo electronico"
        onChange={user.onChange}
        value={user.value}
        name="EMAIL"
        type="user"
        dataCy="login-email"
        placeholder="Ingresa tu correo"
        required
        fullWidth
      />
      <InputField
        label="Contraseña"
        onChange={password.onChange}
        value={password.value}
        onKeyPress={hadleKeyPress}
        name="PASSWORD"
        type="password"
        dataCy="login-password"
        placeholder="Ingresa tu contraseña"
        required
        fullWidth
      />
      {error && (
        <Message
          align="center"
          error={error}
          margin="0"
          data-cy="cy-login-error"
        >
          Usuario o contraseña incorrecta
        </Message>
      )}
      <SecondaryButton
        type="submit"
        id="login-submit"
        fontWeight={500}
        padding={16}
        height="48"
        fullWidth
        data-cy="login-submit"
        disabled={loading || disabled || error}
      >
        {'Iniciar sesión'}
      </SecondaryButton>
    </AuthFormStyled>
  )
}

export default AuthForm
