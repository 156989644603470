import Notification from 'common/notification'

function GithubError({ error }) {

  if (error) {
    let titleError = 'Problemas de vinculación'
    let messageError = 'La cuenta de GitHub no fue vinculada. Intentalo nuevamente o comunicate con nosotros.'
    if (error === 'unique') {
      titleError = 'El usuario ya existe'
      messageError = 'Ya existe un usuario vinculado a esta cuenta de GitHub.'
    }
    if (error === 'max_length') {
      titleError = 'Tu username es demasiado largo'
      messageError = 'Tu nombre de usuario supera los 30 caracteres, te recomendamos cambiarlo.'
    }
    if (error === 'no_public_name') {
      titleError = 'Sin un nombre publico'
      messageError = 'Tu cuenta de github no dispone de un nombre público, este es necesario para registrarte y para que formes una marca personal.'
    }
    return (
      <Notification
        title={titleError}
        message={messageError}
        error
      />
    )
  }

  return null
}

export default GithubError
