import { css, keyframes } from 'styled-components'

const beatKeyFrames = keyframes`
  from {
    transform: scale(1)
  }
  to {
    transform: scale(1.05)
  }
`

export const beat = ({ time = '0.5s', type = 'ease-in-out', repeat = '8' } = {}) => {
  return css`animation: ${beatKeyFrames} ${time} ${repeat} alternate ${type};`
}

const fadeInKeyFrames = keyframes`
  to {
    transform: translateY(0)
  }
  from  {
    transform: translateY(-2px)
  }
`

export const moveUp = ({ time = '.8s', type = 'ease' } = {}) => {
  return css`animation: ${time} ${fadeInKeyFrames} ${type};`
}
