import { useRouter } from 'next/router'
import Notification from 'common/notification'

function ActivateAccountSuccess() {
  const router = useRouter()

  if (router.query.activated) {
    return (
      <Notification
        title="Cuenta Activada"
        message="Inicia sesión con usuario y contraseña para continuar."
        dataCy="activated-account"
      />
    )
  }

  if (router.query.payment) {
    return (
      <Notification
        title="¡Ya tienes acceso!"
        message="Inicia sesión con tu cuenta de Google."
        dataCy="payment-success"
      />
    )
  }

  return null
}

export default ActivateAccountSuccess
